import { ChangeEvent, useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import Loading from './Loading';
import { fetchStatisticById, postStatistic } from '../functions/fetchEntities';
import { Statistics } from '../types/Statistics';


function EditStatistic() {

const [validated, setValidated] = useState(false);
const [selectedStatistic, setSelectedStatistic] = useState({} as Statistics);

const navigate = useNavigate();

const { id } = useParams();
const parsedId = id !== undefined ? id : "";

  useEffect(() => {

    if (parsedId !== "new") {
        fetchStatisticById(parsedId)
            .then((data) => setSelectedStatistic(data));
    }
      
        
    }, [parsedId]);

 
    const handleLessonsChange = (event: ChangeEvent<HTMLInputElement>) => {
        const lessons = parseInt(event.target.value);
        setSelectedStatistic({...selectedStatistic, lessonsTaught: lessons});
    }

    const handleMinutesChange = (event: ChangeEvent<HTMLInputElement>) => {
      const number = parseInt(event.target.value);
      setSelectedStatistic({...selectedStatistic, minutesLearned: number});
    }



    const handleSubmit = async (event:any) => {
        const form = event.currentTarget;
        event.preventDefault();
    
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{
            await postStatistic(selectedStatistic);
            navigate('/Students', {replace: true});
        }
        setValidated(true);
      };

    

    return (
        <>
             {parsedId === "new" || selectedStatistic.id !== undefined ? 
                <div>
                    <h1>Edit Statistic</h1>
                    <div className='form-background'>
                    <div className='form-container'>
                        <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)}>

                        <Form.Group className="mb-3">
                            <Form.Label>Lessons Taught</Form.Label>
                            <Form.Control 
                            type="number" 
                            placeholder="Lessons Taught" 
                            onChange={handleLessonsChange} 
                            value={selectedStatistic.lessonsTaught} 
                            required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Minutes Learned</Form.Label>
                            <Form.Control 
                            type="number" 
                            placeholder="Minutes Learned" 
                            onChange={handleMinutesChange} 
                            value={selectedStatistic.minutesLearned} 
                            required
                            />
                        </Form.Group>

                        <Button className='edit-form-submit' variant="primary" type="submit">
                            Save
                        </Button>
             
                        </Form>

                    </div>
                    </div>
                </div> : <Loading />
             }
        </>
  )
}

export default EditStatistic;