import { useEffect } from 'react'
import AboutSection from './Sections/About';
import StatsSection from './Sections/Stats';
import TestimonialsSection from './Sections/Testimonials';
import PricingSection from './Sections/Pricing';
import ServicesSection from './Sections/Services';
import TeamSection from './Sections/Team';
import ContactSection from './Sections/Contact';
import HeroSection from './Sections/Hero';
import { Link } from 'react-router-dom';

function Home() {
 
  useEffect(() => { 

  }, []);

  return (
    <>
      <header id="header" className="header d-flex align-items-center">
        <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
          <a href="/" className="logo d-flex align-items-center">
            {/* <img src="assets/img/logo.png" alt=""> */}
            <h1>Kílowí<span>.</span>uk</h1>
          </a>
          {/* <nav id="navbar" className="navbar">
            <ul>
              <li><a href="#hero">Home</a></li>
              <li><a href="#about">About</a></li>
              <li><a href="#services">Services</a></li>
              <li><a href="#portfolio">Portfolio</a></li>
              <li><a href="#team">Team</a></li>
              <li><a href="blog.html">Blog</a></li>
              <li className="dropdown"><a href="#"><span>Drop Down</span> <i className="bi bi-chevron-down dropdown-indicator"></i></a>
                <ul>
                  <li><a href="#">Drop Down 1</a></li>
                  <li className="dropdown"><a href="#"><span>Deep Drop Down</span> <i className="bi bi-chevron-down dropdown-indicator"></i></a>
                    <ul>
                      <li><a href="#">Deep Drop Down 1</a></li>
                      <li><a href="#">Deep Drop Down 2</a></li>
                      <li><a href="#">Deep Drop Down 3</a></li>
                      <li><a href="#">Deep Drop Down 4</a></li>
                      <li><a href="#">Deep Drop Down 5</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Drop Down 2</a></li>
                  <li><a href="#">Drop Down 3</a></li>
                  <li><a href="#">Drop Down 4</a></li>
                </ul>
              </li>
              <li><a href="#contact">Contact</a></li>
            </ul>
          </nav> */}

          <i className="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
          <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>

        </div>
      </header>
      <HeroSection />

      <main id="main">
        <AboutSection />
        {/* <CallToActionSection /> */}
        <StatsSection />
        <TestimonialsSection />
        <ServicesSection />
        <TeamSection />
        <PricingSection />
        <ContactSection />
      </main>

      <footer id="footer" className="footer">
        <div className="container mt-4">
          <div className="copyright">
            &copy; Copyright <strong><Link className='secret-link' to="/Password">Kílowí</Link><span>.</span>uk</strong> All Rights Reserved
          </div>
        </div>
      </footer>
    </>
  )
}

export default Home;