import { useEffect, useState } from "react";
import { fetchYorubaPageModel } from "../../functions/fetchEntities";
import { Prices } from "../../types/Prices";


const PricingSection = () => {

  const [prices, setPrices] = useState<Prices[]>([]);


  useEffect(() => {

    const getProperties = async () => {

        const yorubaPageModel = await fetchYorubaPageModel();

        setPrices(yorubaPageModel.awonPrices);

    }

    getProperties();
}, []);

    return (
        <>
          <section id="pricing" className="pricing sections-bg">
            <div className="container" data-aos="fade-up">

              <div className="section-header">
                <h2>Pricing</h2>
                {/* <p>Aperiam dolorum et et wuia molestias qui eveniet numquam nihil porro incidunt dolores placeat sunt id nobis omnis tiledo stran delop</p> */}
              </div>

              <div className="row g-4 py-lg-5" data-aos="zoom-out" data-aos-delay="100">

              {
                prices.map(p => {

                  return (

                    <div className="col-lg-4">
                      <div className={`pricing-item ${p.featured ? "featured" : ""}`}>
                        <h3>{p.tierName}</h3>
                        <div className="icon">
                          <i className={p.icon}></i>
                        </div>
                        <h4><sup>{p.currency}</sup>{p.price}<span> / month</span></h4>
                        <ul>
                          {p.offerings.map(o => {
                            return (
                              <li><i className="bi bi-check"></i> {o}</li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  );

                })
              }

              </div>

            </div>
          </section>

        </>
    );
  };



export default PricingSection;