import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import { useEffect, useState } from 'react';
import { fetchTestimonials } from '../../functions/fetchEntities';
import { Testimonial } from '../../types/Testimonial';




const TestimonialsSection = () => {

  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);


  useEffect(() => {

    const getProperties = async () => {

        const testimonials = await fetchTestimonials();

        setTestimonials(testimonials);

    }

    getProperties();
}, []);

  return (
      <>
        <section id="testimonials" className="testimonials">
    <div className="container" data-aos="fade-up">

      <div className="section-header">
        <h2>Testimonials</h2>
        <p>Discover the impact of Kílowí.uk through the eyes of our students. Their testimonials reflect our dedication to delivering a transformative learning experience.</p>
      </div>

      <div className="slides-3 swiper" data-aos="fade-up" data-aos-delay="100">
        <div className="swiper-wrapper">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={50}
          slidesPerView={3}
          // navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20
            },
            480: {
              slidesPerView: 2,
              spaceBetween: 30
            },
            640: {
              slidesPerView: 3,
              spaceBetween: 40
            }
          }}
        >
          {testimonials.map(testimonial => {

            return (
              <SwiperSlide>
                <div className="swiper-slide">
                  <div className="testimonial-wrap">
                    <div className="testimonial-item">
                      <div className="d-flex align-items-center">
                        {/* <img src="assets/img/testimonials/testimonials-1.jpg" className="testimonial-img flex-shrink-0" alt=""> */}
                        <div>
                          <h3>{testimonial.name}</h3>
                          <div className="stars">
                            <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                          </div>
                        </div>
                        
                      </div>
                      <p>
                        <i className="bi bi-quote quote-icon-left"></i>
                          {testimonial.message}
                        <i className="bi bi-quote quote-icon-right"></i>
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}

        </Swiper>
        </div>
        <div className="swiper-pagination"></div>
      </div>

    </div>
  </section>
      </>
  );
};

export default TestimonialsSection;