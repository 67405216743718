import about from '../../img/yoruba.exe about us img.png';
import about2 from '../../img/about 2.png';

const AboutSection = () => {
    return (
        <>
            <section id="about" className="about">
          <div className="container" data-aos="fade-up">
{/* 
            <div className="section-header">
              <h2>About Us</h2>
              <p>Aperiam dolorum et et wuia molestias qui eveniet numquam nihil porro incidunt dolores placeat sunt id nobis omnis tiledo stran delop</p>
            </div> */}

            <div className="row gy-4">
              <div className="col-lg-6">
                <h3>Your Journey to Yoruba Fluency Starts Here</h3>
                <img src={about2} className="img-fluid rounded-4 mb-4" alt="" />
                <p>From beginners to advanced learners, our online platform is your space to master Yoruba at your own pace. Connect, converse, and celebrate the language with a global community.</p>
                <p>Our expert teachers bring the richness of Yoruba to you, wherever you are. With interactive lessons, cultural immersion, and comprehensive support, we make language learning a journey of discovery.</p>
              </div>
              <div className="col-lg-6">
                <div className="content ps-0 ps-lg-5">
                  <p className="fst-italic">
                    Embark on a language adventure with our dynamic online lessons. Experience the richness of Yoruba culture and communicate with ease.
                  </p>
                  <ul>
                    <li><i className="bi bi-check-circle-fill"></i> Engage in live, interactive classes that bring you face-to-face with our expert Yoruba language instructors.</li>
                    <li><i className="bi bi-check-circle-fill"></i> Customise your learning experience with courses that adapt to your proficiency level and learning pace.</li>
                    <li><i className="bi bi-check-circle-fill"></i> Go beyond grammar and vocabulary with modules dedicated to Yoruba culture, traditions, and history.</li>
                  </ul>
                  <p>
                    Join Kilowi.uk today and connect with a community of learners and educators passionate about Yoruba language and culture. With personalised feedback, cultural immersion, and supportive learning tools, we’re here to guide you every step of the way on your journey to fluency.
                  </p>

                  <div className="position-relative mt-4">
                    <img src={about} className="img-fluid rounded-4" alt="" />
                    {/* <a href="https://www.youtube.com/watch?v=LXb3EKWsInQ" className="glightbox play-btn">.</a> */}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>

        </>
    );
  };



export default AboutSection;