import { useEffect, useState } from 'react';
import { Accordion, Button, Form } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Link, useNavigate } from 'react-router-dom';
import { Student } from '../types/Student';
import { fetchStudents, fetchTestimonials, postStudent, sendReminderId, fetchStatisticById } from '../functions/fetchEntities';
import { Statistics } from '../types/Statistics';
import { Testimonial } from '../types/Testimonial';

function Students() {

  const navigate = useNavigate();

//   const [isLoading, setIsLoading] = useState(true);

  const [students, setStudents] = useState<Student[]>([]);
  const [statistics, setStatistics] = useState<Statistics>({} as Statistics);
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const [totalAmount, setTotalAmount] = useState<number>();

    const handleNavigate = (id: string) => {
        navigate(`/Students/${id}`, {replace: true});
    }

    const handleStatNavigate = (id: string) => {
        navigate(`/Statistic/${id}`, {replace: true});
    }

    const handleTestimonialNavigate = (id: string) => {
        navigate(`/Testimonial/${id}`, {replace: true});
    }

    const handleCheckStudent = async (id: string) => {

        const student = students.filter(s => s.id === id)[0];

        student.shouldSendReminder = !student.shouldSendReminder;
        await postStudent(student);

        navigate(`/Students`, {replace: true});
    }

    const sendReminder = (id: string) => {
        sendReminderId(id)
        alert("Done");
    }

    const calculateTotal = (students: Student[]):number => {

        let amount = 0;
        
        students.map(s => amount += s.fee)

        return amount;
    }

    useEffect(() => {

        const getProperties = async () => {
            const students = await fetchStudents();
            const statistics = await fetchStatisticById("657c6da6-dad7-4a6f-8d80-6da853a2494a");
            const testimonials = await fetchTestimonials();

            setStudents(students);
            setStatistics(statistics);
            setTestimonials(testimonials);
            setTotalAmount(calculateTotal(students));
        }

        getProperties();
    }, []);

    return (
        <>
            

            <Accordion defaultActiveKey="students">
                <Accordion.Item eventKey="students">
                    <Accordion.Header><h3 className='centered'>Students</h3></Accordion.Header>
                    <Accordion.Body>
                    <div>
                        <Link className="navitem" to="/Students/new">
                            <Button variant="primary" className="mb-3">
                                Add Student
                            </Button>
                        </Link>
                        <br />
                        <span>Total Amount: £{totalAmount}</span>
                        <Table responsive striped hover>
                            <thead>
                            <tr>
                                <th>Send reminder</th> 
                                <th>Name</th> 
                                <th>Day</th> 
                                <th>Time</th> 
                                <th>Teacher</th> 
                                <th>Money</th> 
                                <th></th> 
                            </tr>
                            </thead>
                            <tbody>
                            {
                                students.map((student : Student) => {
                                    return (
                                        <tr key={student.id}>
                                            <td>
                                                <Form.Check type="switch" checked={student.shouldSendReminder} onClick={e => handleCheckStudent(student.id)} />
                                            </td>
                                            <td onClick={e => handleNavigate(student.id)}>{student.name}</td>
                                            <td onClick={e => handleNavigate(student.id)}>{student.dayOfTheWeek}</td>
                                            <td onClick={e => handleNavigate(student.id)}>{student.timeOfTheDay}</td>
                                            <td onClick={e => handleNavigate(student.id)}>{student.teacher}</td>
                                            <td onClick={e => handleNavigate(student.id)}>£{student.fee}</td>
                                            <td >
                                                <Button variant="primary" className="mb-3" onClick={e => sendReminder(student.id)} disabled={!student.shouldSendReminder}>
                                                    Send Reminder
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                    </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="statistics">
                    <Accordion.Header><h3 className='centered'>Statistics</h3></Accordion.Header>
                    <Accordion.Body>
                    <div>
                        <Table responsive striped hover>
                            <thead>
                            <tr>
                                <th>Students</th> 
                                <th>Lessons Taught</th> 
                                <th>Minutes Learned</th> 
                            </tr>
                            </thead>
                            <tbody>
                                <tr key={statistics.id} onClick={e => handleStatNavigate(statistics.id)}>
                                    <td>{statistics.students}</td>
                                    <td>{statistics.lessonsTaught}</td>
                                    <td>{statistics.minutesLearned}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="testimonial">
                    <Accordion.Header><h3 className='centered'>Testimonials</h3></Accordion.Header>
                    <Accordion.Body>
                    <div>
                    <Link className="navitem" to="/Testimonial/new">
                        <Button variant="primary" className="mb-3">
                            Add Testimonial
                        </Button>
                    </Link>
                        <Table responsive striped hover>
                            <thead>
                            <tr>
                                <th>Name</th> 
                                <th>Message</th> 
                            </tr>
                            </thead>
                            <tbody>
                            {
                                testimonials.map((testimonial : Testimonial) => {
                                    return (
                                        <tr key={testimonial.id} onClick={e => handleTestimonialNavigate(testimonial.id)}>
                                            <td>{testimonial.name}</td>
                                            <td>{testimonial.message}</td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                    </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}

export default Students;