

const ServicesSection = () => {
    return (
        <>
          <section id="services" className="services sections-bg">
            <div className="container" data-aos="fade-up">

              <div className="section-header">
                <h2>What we offer</h2>
                {/* <p>Aperiam dolorum et et wuia molestias qui eveniet numquam nihil porro incidunt dolores placeat sunt id nobis omnis tiledo stran delop</p> */}
              </div>

              <div className="row gy-4" data-aos="fade-up" data-aos-delay="100">



                <div className="col-lg-4 col-md-6">
                  <div className="service-item position-relative">
                    <div className="icon">
                      <i className="bi bi-easel"></i>
                    </div>
                    <h3>Interactive learning</h3>
                    <p>Our interactive lessons are designed to keep you engaged and motivated, with activities that simulate real-world Yoruba language use.</p>
                  </div>
                </div>


                <div className="col-lg-4 col-md-6">
                  <div className="service-item position-relative">
                    <div className="icon">
                      <i className="bi bi-calendar4-week"></i>
                    </div>
                    <h3>Flexible lessons</h3>
                    <p>Our flexible curriculum allows you to balance your language learning with your schedule, making it easy to progress at your own pace.</p>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="service-item position-relative">
                    <div className="icon">
                      <i className="bi bi-chat-square-text"></i>
                    </div>
                    <h3>Continous support</h3>
                    <p>Our dedicated team offers ongoing support, ensuring your language challenges are met with understanding and guidance.</p>
                  </div>
                </div>

              </div>

            </div>
          </section>
        </>
    );
  };



export default ServicesSection;