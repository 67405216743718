import React, { Component } from 'react';
import Home from '../Home';
import Students from '../Students';
import EditStudent from '../EditStudent';
import {
  BrowserRouter as Router,
  Routes,
  Route
 } from "react-router-dom";
import EditStatistic from '../EditStatistic';
import EditTestimonial from '../EditTestimonial';
import EditLesson from '../EditLesson';
import Password from '../Password';
import HelperPages from '../HelperPages';
import Lessons from '../Lessons';


export default class NavbarComp extends Component {


 render() {
   return (
     <Router>
       <div>
       <Routes>
         <Route path="/" element={<Home />} />
         <Route path="/Students" element={<Students />} />
         <Route path="/Students/:id" element={<EditStudent />} />
         <Route path="/Statistic/:id" element={<EditStatistic />} />
         <Route path="/Testimonial/:id" element={<EditTestimonial />} />
         <Route path="/EditLesson/:id" element={<EditLesson />} />
         <Route path="/Lessons" element={<Lessons />} />
         <Route path="/Password" element={<Password />} />
         <Route path='/HelperPages' element={<HelperPages />} />
       </Routes>
       </div>
     </Router>
   )
 }
}
