import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';

function HelperPages() {

  const navigate = useNavigate();

  const navigateToStudents = () => {
        navigate('/Students', {replace: true});
        
    }

    const navigateToLessons = () => {
        navigate('/Lessons', {replace: true});
        
    }

    return (
        <>
            <Table responsive striped hover>
                <thead>
                <tr>
                    <th>Page</th> 
                </tr>
                </thead>
                <tbody>
                    <tr key="1"  onClick={navigateToStudents}>
                        <td>Students</td>
                    </tr>
                    <tr key="2"  onClick={navigateToLessons}>
                        <td>Lessons</td>
                    </tr>
                </tbody>
            </Table>
        </>
    )
}

export default HelperPages;