import { ChangeEvent, useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import Loading from './Loading';
import { createSlide, fetchLessonById, postLesson } from '../functions/fetchEntities';
import { TranslationModel, TranslationObject } from '../types/TranslationModel';
import { createGuid } from '../functions/createGuid';

function EditLesson() {

const templates = 
[
    { name: "Dialouge", templateID: "1ST9yrr7-iQSs5hwEKwWchmON38lPD3mm17X6QoH9jhI"},
    { name: "Vocabulary", templateID: "1c34C0yakbVkDoeg26zstzfUkmv1W8i5orxkjTmjHP8k"},
    { name: "Vocabulary (with pictures)", templateID: "1Xh8p7uC85MaehNHaPx3J5uMt9cPGGzEbRGc5uJZGkKM"}
]

const slidesIds = 
[
    "g29b28d59786_0_0,g2b406331d07_0_140",
    "g2b406331d07_0_2,g2b406331d07_0_147",
    "g2b406331d07_0_9,g2b406331d07_0_154",
    "g2b406331d07_0_16,g2b406331d07_0_266",
    "g2b406331d07_0_23,g2b406331d07_0_161",
    "g2b406331d07_0_30,g2b406331d07_0_168",
    "g2b406331d07_0_37,g2b406331d07_0_175",
    "g2b406331d07_0_44,g2b406331d07_0_182",
    "g2b406331d07_0_51,g2b406331d07_0_189",
    "g2b406331d07_0_58,g2b406331d07_0_196",
    "g2b406331d07_0_65,g2b406331d07_0_203",
    "g2b406331d07_0_72,g2b406331d07_0_210",
    "g2b406331d07_0_79,g2b406331d07_0_217",
    "g2b406331d07_0_86,g2b406331d07_0_224",
    "g2b406331d07_0_93,g2b406331d07_0_231",
    "g2b406331d07_0_100,g2b406331d07_0_238",
    "g2b406331d07_0_107,g2b406331d07_0_245",
    "g2b406331d07_0_114,g2b406331d07_0_252",
    "g2b406331d07_0_121,g2b406331d07_0_259",
    "g2b406331d07_0_128,g2b406331d07_0_273"
]

const [validated, setValidated] = useState(false);
const [translationModel, setTranslationModel] = useState({ id: createGuid(), templateId: templates[0].templateID, content: [] as TranslationObject[]} as TranslationModel);
const [showImageUrl, setShowImageUrl] = useState(false);

const [showModal, setShowModal] = useState(false);
const [bulkImportText, setBulkImportText] = useState('');

const [language, setLanguage] = useState('English');

const [languageExport, setLanguageExport] = useState('');

const navigate = useNavigate();

const { id } = useParams();
const parsedId = id !== undefined ? id : "";

const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value.trim();
    setTranslationModel({...translationModel, title: text});
}

const getTranslationId = (event: ChangeEvent<HTMLInputElement>) => {
    const translationID = event.target.getAttribute('data-id');

    return translationID ?? "NONE FOUND";
}

const getValue = (event: ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value.trim();

    return text;
}

const findTranslationModelIndex = (content: TranslationObject[], translationID: string) => {

    const index = content.findIndex(t => t.id === translationID);

    return index;
}

const getTranslation = (content: TranslationObject[], index: number) => {
    return content[index];
}

const handlePositionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const text = getValue(event);
    const translationID = getTranslationId(event);

    const content = translationModel.content;
    const index = findTranslationModelIndex(content, translationID);
    const translation = getTranslation(content, index);

    translation!.position = parseInt(text);
    content[index] = translation;

    setTranslationModel({...translationModel, content: content});
}


const handleEnglishChange = (event: ChangeEvent<HTMLInputElement>) => {
    const text = getValue(event);
    const translationID = getTranslationId(event);

    const content = translationModel.content;
    const index = findTranslationModelIndex(content, translationID);
    const translation = getTranslation(content, index);

    translation!.english = text;
    content[index] = translation;

    setTranslationModel({...translationModel, content: content});
}

const handleYorubaChange = (event: ChangeEvent<HTMLInputElement>) => {
    const text = getValue(event);
    const translationID = getTranslationId(event);

    const content = translationModel.content;
    const index = findTranslationModelIndex(content, translationID);
    const translation = getTranslation(content, index);

    translation!.yoruba = text;
    content[index] = translation;

    setTranslationModel({...translationModel, content: content});
}

const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const url = getValue(event);
    const translationID = getTranslationId(event);

    const content = translationModel.content;
    const index = findTranslationModelIndex(content, translationID);
    const translation = getTranslation(content, index);

    translation!.imageUrl = url;
    content[index] = translation;

    setTranslationModel({...translationModel, content: content});
}

const handleTranslateDelete = (event: any) => {
    const translationID = getTranslationId(event);

    const content = translationModel.content;
    const index = findTranslationModelIndex(content, translationID);
    content.splice(index, 1);


    setTranslationModel({...translationModel, content: content});
}

const handleTemplateChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const templateID = event.target.value;

    if (templateID === templates[2].templateID) {
        setShowImageUrl(true);
    }else{
        setShowImageUrl(false);
    }

    setTranslationModel({...translationModel, templateId: templateID});
}

const handleLanguageExport = (mode: string) => {
    var list = "";

    translationModel.content.map(tm => {
        if (mode === "Export English") {
            list = list + `${tm.english}\n`;
        }else{
            list = list + `${tm.yoruba}\n`;
        }

        return '';
    });

    setLanguageExport(list);
}

const handleLanguageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const language = event.target.value;

    if (language === "English" || language === "Yoruba") {
        setLanguage(language);
        setLanguageExport("");
    }else{
        handleLanguageExport(language);
    }

}

const addNewTranslation = () => {
    
    let content = translationModel.content;
    const position = content.length + 1;

    const newTranslation = 
    {
        id: createGuid(),
        position: position
    } as TranslationObject;

    content.push(newTranslation);

    setTranslationModel({...translationModel, content: content});
}

const handleSave = async (event:any) => {
    const form = event.currentTarget;
    event.preventDefault();

    updateSlidesIDs()

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }else{
        await postLesson(translationModel);
        navigate('/Lessons', {replace: true});
    }
    setValidated(true);
};

const handleCreateSlides = async (event:any) => {


    updateSlidesIDs()
    
    await createSlide(translationModel);
    navigate('/Lessons', {replace: true});

};

const updateSlidesIDs = () => {

    const content = translationModel.content;

    const newContent = [];

    for (let index = 0; index < content.length; index++) {
        const element = content[index];

        element.slideIds = slidesIds[index];

        newContent.push(element);
    }

    setTranslationModel({...translationModel, content: newContent});
}

const handleClose = () => setShowModal(false);
const handleShow = () => setShowModal(true);

const handleBulkImportChange = (event: ChangeEvent<HTMLTextAreaElement>) => {

    const text = event.target.value;

    setBulkImportText(text);
}

const handleBulkImportSubmit = () => {

    const imports = bulkImportText.split("\n");

    const content = translationModel.content;

    const newContent = [];

    for (let index = 0; index < content.length; index++) {
        const element = content[index];

        if (language === "English") {
            element.english = imports[index]?.trim();
        }else{
            element.yoruba = imports[index]?.trim();
        }

        newContent.push(element);
    }

    setTranslationModel({...translationModel, content: newContent});
    setBulkImportText("");
    handleClose()
}

const addTwentyTranslations = () => {

    for (let index = 0; index < 20; index++) {

        addNewTranslation();
    }
}


  useEffect(() => {

    if (parsedId !== "new") {
      fetchLessonById(parsedId)
            .then((data) => {
                setTranslationModel(data)
                setShowImageUrl(data.templateId === "1Xh8p7uC85MaehNHaPx3J5uMt9cPGGzEbRGc5uJZGkKM");
            });
    }
        
    }, [parsedId]);

    return (
        <>
             {parsedId === "new" || translationModel.id !== undefined ? 
                <div>
                    <h1>Edit Lesson</h1>
                    <div className='form-background'>
                    <div className='form-container'>
                        <Form noValidate validated={validated} onSubmit={event => handleSave(event)}>

                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Lesson Title</Form.Label>
                            <Form.Control 
                            type="text" 
                            placeholder="Lesson Title" 
                            onChange={handleTitleChange} 
                            value={translationModel.title} 
                            required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formDOW">
                            <Form.Label>Lesson Template</Form.Label>
                            <select className="form-select" aria-label="Class" onChange={handleTemplateChange}>
                                {templates.map((t) => (
                                    <option value={t.templateID} selected={translationModel.templateId === t.templateID}>{t.name}</option>
                                ))}
                            </select>
                        </Form.Group>

                        <Button variant="primary" onClick={handleShow}>
                            Bulk Import
                        </Button>

                        <Modal show={showModal} onHide={handleClose}>
                            <Modal.Header closeButton>
                            <Modal.Title>
                                Bulk Import
                                <br />
                                <Button variant="primary" onClick={addTwentyTranslations}>
                                    Add 20 Translations
                                </Button>
                            </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <Form>
                                <Form.Group className="mb-3" controlId="formDOW">
                                    <Form.Label>Lesson Template</Form.Label>
                                    <select className="form-select" aria-label="Class" onChange={handleLanguageChange}>
                                        <option value="English">English</option>
                                        <option value="Yoruba">Yoruba</option>
                                        <option value="Export English">Export English</option>
                                        <option value="Export Yoruba">Export Yoruba</option>
                                    </select>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Text</Form.Label>
                                    <Form.Control as="textarea" rows={20} onChange={handleBulkImportChange} defaultValue={languageExport} />
                                </Form.Group>
                            </Form>
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleBulkImportSubmit}>
                                Save Changes
                            </Button>
                            </Modal.Footer>
                        </Modal>

                        <hr className="solid"></hr>
                        {
                          translationModel.content.map((content, index)=> {
                            const i = index + 1;

                            return (
                            <>
                                <br />

                                <div key={index}>
                                    <Form.Group className="mb-3" controlId="formEnglish">
                                        <Form.Control 
                                        className='translation-position'
                                        type="number" 
                                        placeholder="Position" 
                                        onChange={handlePositionChange} 
                                        value={content.position} 
                                        data-id={content.id}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formEnglish">
                                        <Form.Control 
                                        type="text" 
                                        placeholder="English" 
                                        onChange={handleEnglishChange} 
                                        value={content.english} 
                                        data-id={content.id}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formEnglish">
                                        <Form.Control 
                                        type="text" 
                                        placeholder="Yoruba" 
                                        onChange={handleYorubaChange} 
                                        value={content.yoruba} 
                                        data-id={content.id}
                                        />
                                    </Form.Group>

                                    {
                                        (
                                            content.imageUrl !== undefined && content.imageUrl !== "" && content.imageUrl !== null) && (
                                            <img src={content.imageUrl} className='translation-img' alt={content.english} />
                                        )
                                    }

                                    {
                                        showImageUrl === true && (
                                            <Form.Group className="mb-3" controlId="formUrl">
                                                <Form.Label>Image URL</Form.Label>
                                                <Form.Control 
                                                type="text" 
                                                placeholder="Image URL" 
                                                onChange={handleImageChange} 
                                                value={content.imageUrl} 
                                                data-id={content.id}
                                                />
                                            </Form.Group>
                                        )
                                    }

                                    <Button className='edit-form-submit' variant="danger" data-id={content.id} onClick={handleTranslateDelete}>
                                        X
                                    </Button>

                                    <br />
                                </div>
                                <hr className={i % 5 === 0 ? "solid" : ""}></hr>
                            </>);
                          })
                        }

                        <Button className='edit-form-submit' variant="primary" onClick={addNewTranslation}>
                            Add New
                        </Button>
                        <br />
                        <br />
                        <Button className='edit-form-submit' variant="primary" type="submit">
                            Save
                        </Button>
                        <br />
                        <br />
                        <Button className='edit-form-submit' variant="success" onClick={handleCreateSlides}>
                            Create Slides
                        </Button>
             
                        </Form>
                    </div>
                    </div>
                </div> : <Loading />
             }
        </>
  )
}

export default EditLesson;