import { ChangeEvent, useEffect, useState } from "react";
import { Button, Form } from 'react-bootstrap';
import { postEnquiry } from "../../functions/fetchEntities";

const ContactSection = () => {

  const [client, setClient] = useState({name: "", phoneNumber: "", subject: ""});
  const [validated, setValidated] = useState(false);

  useEffect(() => { 


  }, []);

  const handleSubmit = async (event:any) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }else{
        
      await postEnquiry(client);

      alert(`Thank you ${client.name}, We will be in contact soon`);

      client.name = "";
      client.phoneNumber = "";
      client.subject = "";

      setClient(client);

      window.location.reload();
    }

    setValidated(true);
  };

  function handleNameChange(event: ChangeEvent<HTMLInputElement>): void {
      const name = event.target.value;

      setClient({...client, name: name});
  }

  function handlePhoneNumberChange(event: ChangeEvent<HTMLInputElement>): void {
    const phoneNumber = event.target.value;

    setClient({...client, phoneNumber: phoneNumber});
  }

  function handleSubjectChange(event: ChangeEvent<HTMLTextAreaElement>): void {
    const subject = event.target.value;

    setClient({...client, subject: subject});
  }

  
    return (
        <>
          <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">

            <div className="section-header">
              <h2>Contact</h2>
              <p>Interested in learning more about our Yoruba language programs? Contact our friendly staff for more information.</p>
            </div>

            <div className="row gx-lg-0 gy-4">

              <div className="col-lg-4">

                <div className="info-container d-flex flex-column align-items-center justify-content-center">
                  <div className="info-item d-flex">
                    <i className="bi bi-geo-alt flex-shrink-0"></i>
                    <div>
                      <h4>Location:</h4>
                      <p>UK/USA/Canada</p>
                    </div>
                  </div>

                  <div className="info-item d-flex">
                    <i className="bi bi-envelope flex-shrink-0"></i>
                    <div>
                      <h4>Email:</h4>
                      <p>mr-ea@hotmail.co.uk</p>
                    </div>
                  </div>

                  <div className="info-item d-flex">
                    <i className="bi bi-phone flex-shrink-0"></i>
                    <div>
                      <h4>Call:</h4>
                      <p>+447 429424660</p>
                    </div>
                  </div>
                </div>

              </div>

              <div className="col-lg-8">
              
                <Form className="php-email-form" noValidate validated={validated} onSubmit={event => handleSubmit(event)}>
                  <Form.Group className="mb-3">
                      <Form.Label>Your Name</Form.Label>
                      <Form.Control 
                      id="edit-name"
                      type="text" 
                      placeholder="Your Name" 
                      value={client.name} 
                      onChange={handleNameChange} 
                      required
                      />
                  </Form.Group>

                  <Form.Group className="mb-3">
                      <Form.Label>Your Phone Number</Form.Label>
                      <Form.Control 
                      id="edit-phone-number"
                      type="text" 
                      placeholder="Your Phone Number" 
                      value={client.phoneNumber} 
                      onChange={handlePhoneNumberChange} 
                      required
                      />
                  </Form.Group>

                  <Form.Group className="mb-3">
                      <Form.Label>Subject</Form.Label>
                      <textarea id="edit-subject" className="form-control" rows={8} onChange={handleSubjectChange} />
                  </Form.Group>

                  <Button id="save" className='text-center' variant="primary" type="submit">
                      Send
                  </Button>
                </Form>

              

                
              </div>

            </div>

          </div>
        </section>

        </>
    );
  };

export default ContactSection;