import { ClientEnquiry } from "../types/ClientEnquiry";
import { Statistics } from "../types/Statistics";
import { Student } from "../types/Student";
import { Testimonial } from "../types/Testimonial";
import { TranslationModel } from "../types/TranslationModel";
import { YorubaPageModel } from "../types/YorubaPageModel";

const domain = "https://aremusoftware.azurewebsites.net/api/";
// const domain = "http://localhost:7071/api";

const getStudentURL = `${domain}/GetStudent`;
const getStudentsURL = `${domain}/GetStudents`;
const postStudentURL = `${domain}/PostStudent`;
const deleteStudentURL = `${domain}/DeleteStudent`;
const sendReminderURL = `${domain}/YorubaReminderTrigger`;

const getStatisticByIDURL = `${domain}/GetStatistic`;
const getYorubaPageModelURL = `${domain}/GetYorubaPageModel`;

const postStatisticURL = `${domain}/PostStatistic`;

const getTestimonialByIDURL = `${domain}/GetTestimonial`;
const getTestimonialsURL = `${domain}/GetTestimonials`;
const getLessonsURL = `${domain}/GetYorubaLessons`;
const getLessonByIdURL = `${domain}/GetYorubaLesson`;

const postTestimonialURL = `${domain}/PostTestimonial`;

const postEnquiryURL = `${domain}/PostEnquiry`;


const postLessonURL = `${domain}/PostYorubaLesson`;
const createSlideURL = `${domain}/CreateYorubaSlides`;

export const fetchStudents = async () => {
    const response = await fetch(getStudentsURL)
            .then(response => response.json() as Promise<Student[]>)

    return response;
};

export const fetchStudentById = async (id:string) => {
    const response = await fetch(`${getStudentURL}/${id}`)
            .then(response => response.json() as Promise<Student>)

    return response;
};

export const fetchStatisticById = async (id:string) => {
    const response = await fetch(`${getStatisticByIDURL}/${id}`)
            .then(response => response.json() as Promise<Statistics>)

    return response;
};

export const fetchYorubaPageModel = async () => {
    const response = await fetch(`${getYorubaPageModelURL}`)
            .then(response => response.json() as Promise<YorubaPageModel>)

    return response;
};

export const fetchTestimonial = async (id: string) => {
    const response = await fetch(`${getTestimonialByIDURL}/${id}`)
            .then(response => response.json() as Promise<Testimonial>)

    return response;
};

export const fetchTestimonials = async () => {
    const response = await fetch(`${getTestimonialsURL}`)
            .then(response => response.json() as Promise<Testimonial[]>)

    return response;
};

export const fetchLessons = async () => {
    const response = await fetch(`${getLessonsURL}`)
            .then(response => response.json() as Promise<TranslationModel[]>)

    return response;
};

export const fetchLessonById = async (id:string) => {
    const response = await fetch(`${getLessonByIdURL}/${id}`)
            .then(response => response.json() as Promise<TranslationModel>)

    return response;
};

export const postLesson = async (lesson: TranslationModel) => {
    const response = await fetch(postLessonURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(lesson)
    });

    return response;
};

export const createSlide = async (lesson: TranslationModel) => {
    const response = await fetch(createSlideURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(lesson)
    });

    return response;
};

export const sendReminderId = async (id:string) => {
    const response = await fetch(`${sendReminderURL}?id=${id}`)
            .then(response => response.json() as Promise<string>)

    return response;
};

export const postEnquiry = async (enquiry: ClientEnquiry) => {
    const response = await fetch(postEnquiryURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(enquiry)
    });

    return response;
};

export const postStudent = async (student:Student) => {
    const response = await fetch(postStudentURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(student)
    });

    return response;
};

export const postStatistic = async (statistic: Statistics) => {
    const response = await fetch(postStatisticURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(statistic)
    });

    return response;
};

export const postTestimonial = async (testimonial: Testimonial) => {
    const response = await fetch(postTestimonialURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(testimonial)
    });

    return response;
};

export const deleteStudent = async (studentId:string) => {

    const response = await fetch(`${deleteStudentURL}/${studentId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return response;
};

export const fetchTimesOfDay = () => {
    const dow = [
        '13:00', 
        '13:30', 
        '14:00', 
        '14:30', 
        '15:00', 
        '15:30', 
        '16:00', 
        '16:30', 
        '17:00', 
        '17:30', 
        '18:00', 
        '18:30', 
        '19:00', 
        '19:30', 
        '20:00', 
        '20:30', 
        '21:00', 
        '21:30', 
        '22:00', 
        '22:30', 
        '23:00'];

    return dow;
}

export const fetchTimesZones = () => {
    const dow = ['Europe/London', 'America/New_York'];

    return dow;
}

export const fetchDaysOfTheWeek = () => {
    const dow = [
        'Monday', 
        'Tuesday', 
        'Wednesday', 
        'Thursday', 
        'Friday', 
        'Saturday', 
        'Sunday'];

    return dow;
}

export const fetchTeachers = () => {
    const teachers = ['Saheed', 'Elijah'];

    return teachers;
}