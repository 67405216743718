import React, { useEffect } from 'react';
import './App.css';
import 'aos/dist/aos.css';
import AOS from 'aos';
import NavbarComp from './Components/Navbar/NavComp';


function App() {

  useEffect(() => {
    AOS.init({});
  }, []);
  
  return (
    <div className="App">
    <NavbarComp />
  </div>
  );
}

export default App;
