import { useEffect, useState } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Link, useNavigate } from 'react-router-dom';
import { fetchLessons } from '../functions/fetchEntities';
import { TranslationModel } from '../types/TranslationModel';

function Lessons() {

  const navigate = useNavigate();

  const [lessons, setLessons] = useState<TranslationModel[]>([]);

    const handleNavigate = (id: string) => {
        navigate(`/EditLesson/${id}`, {replace: true});
    }

    useEffect(() => {

        const getProperties = async () => {
            const lessons = await fetchLessons();

            setLessons(lessons);
        }

        getProperties();
    }, []);

    return (
        <>
            

            <Accordion defaultActiveKey="vocabulary">
                <Accordion.Item eventKey="vocabulary">
                    <Accordion.Header><h3 className='centered'>Vocabulary</h3></Accordion.Header>
                    <Accordion.Body>
                    <div>
                        <Link className="navitem" to="/EditLesson/new">
                            <Button variant="primary" className="mb-3">
                                Add Lesson
                            </Button>
                        </Link>
                        <br />
                        <Table responsive striped hover>
                            <thead>
                            <tr>
                                <th></th> 
                                <th></th> 
                            </tr>
                            </thead>
                            <tbody>
                            {
                                lessons.map((lesson : TranslationModel) => {
                                    return (
                                        <tr >
                                            <td>{lesson.title}</td>
                                            <td>
                                                <Button variant="primary" className="mb-3" onClick={e => handleNavigate(lesson.id)} >
                                                    Edit
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                    </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}

export default Lessons;