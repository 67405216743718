import { ChangeEvent, useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import Loading from './Loading';
import { fetchTestimonial, postTestimonial } from '../functions/fetchEntities';
import { Testimonial } from '../types/Testimonial';


function EditTestimonial() {

const [validated, setValidated] = useState(false);
const [selectedTestimonial, setSelectedTestimonial] = useState({} as Testimonial);

const navigate = useNavigate();

const { id } = useParams();
const parsedId = id !== undefined ? id : "";

  useEffect(() => {

    if (parsedId !== "new") {
        fetchTestimonial(parsedId)
            .then((data) => setSelectedTestimonial(data));
    }
      
        
    }, [parsedId]);

 
    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        setSelectedTestimonial({...selectedTestimonial, name: name});
    }

    const handleMessageChange = (event: ChangeEvent<HTMLInputElement>) => {
      const message = event.target.value;
      setSelectedTestimonial({...selectedTestimonial, message: message});
    }



    const handleSubmit = async (event:any) => {
        const form = event.currentTarget;
        event.preventDefault();
    
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{
            await postTestimonial(selectedTestimonial);
            navigate('/Students', {replace: true});
        }
        setValidated(true);
      };

    

    return (
        <>
             {parsedId === "new" || selectedTestimonial.id !== undefined ? 
                <div>
                    <h1>Edit Testimonial</h1>
                    <div className='form-background'>
                    <div className='form-container'>
                        <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)}>

                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control 
                            type="test" 
                            placeholder="Name" 
                            onChange={handleNameChange} 
                            value={selectedTestimonial.name} 
                            required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Message</Form.Label>
                            <Form.Control 
                            type="text" 
                            placeholder="Message" 
                            onChange={handleMessageChange} 
                            value={selectedTestimonial.message} 
                            required
                            />
                        </Form.Group>

                        <Button className='edit-form-submit' variant="primary" type="submit">
                            Save
                        </Button>
             
                        </Form>

                    </div>
                    </div>
                </div> : <Loading />
             }
        </>
  )
}

export default EditTestimonial;