
import elijah from "../../img/elijah.jpg";
import saheed from "../../img/Saheed.jpg";
import stockPhotoM from "../../img/blank profile photo M.png";
import stockPhotoF from "../../img/blank profile photo F.png";


const TeamSection = () => {
    return (
        <>
          <section id="team" className="team">
          <div className="container" data-aos="fade-up">

            <div className="section-header">
              <h2>Our Team</h2>
              <p>Discover the faces behind our innovative approach to learning Yoruba. We blend tradition with technology to deliver an unmatched educational experience.</p>
            </div>

            <div className="row gy-4">

              <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="100">
                <div className="member">
                  <img src={elijah} className="img-fluid" alt="" />
                  <h4>Elijah</h4>
                  <span>Director</span>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="200">
                <div className="member">
                  <img src={saheed} className="img-fluid" alt="" />
                  <h4>Saheed</h4>
                  <span>Lead Teacher</span>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="400">
                <div className="member">
                  <img src={stockPhotoM} className="img-fluid" alt="" />
                  <h4>Mustapha</h4>
                  <span>Teacher</span>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="400">
                <div className="member">
                  <img src={stockPhotoF} className="img-fluid" alt="" />
                  <h4>Jane</h4>
                  <span>Content Creator</span>
                </div>
              </div>
            </div>

          </div>
        </section>

        </>
    );
  };



export default TeamSection;