import { ChangeEvent, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
// import { SendSummaryMessage, deleteRabbits } from '../../../functions/fetchEntities';

function Password() {

  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const sitePassword = "tola";

    const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        setPassword(text);
    }


    const handleSubmit = async (event:any) => {

        if (sitePassword === password) {
            navigate('/HelperPages', {replace: true});

            
        }else{
            alert("Incorrect");
        }

        };


    return (
        <>
            <div>
                    <h1>Enter Password</h1>
                    <div className='form-background'>
                    <div className='form-container'>
                        <Form noValidate onSubmit={event => handleSubmit(event)}  >

                        <Form.Group className="mb-3">
                            <Form.Control 
                            type="text" 
                            placeholder="Password" 
                            onChange={handlePasswordChange} 
                            value={password} 
                            required
                            />
                        </Form.Group>

                        <Button className='edit-form-submit' variant="primary" type="submit">
                            Submit
                        </Button>
             
                        </Form>

                    </div>
                    </div>
                </div>
        </>
    )
}

export default Password;