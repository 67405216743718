import CounterComponent from "../../CounterComponent";
import stats from '../../img/stats-img.svg';
import { useEffect, useState } from "react";
import { Statistics } from "../../types/Statistics";
import { fetchStatisticById } from "../../functions/fetchEntities";

const StatsSection = () => {

  const [statistics, setStatistic] = useState<Statistics>({} as Statistics);

  useEffect(() => {
    const getProperties = async () => {
        const statistics = await fetchStatisticById("657c6da6-dad7-4a6f-8d80-6da853a2494a");
        setStatistic(statistics);
    }

    getProperties();
}, []);


    return (
        <>
         <section id="stats-counter" className="stats-counter">
          <div className="container" data-aos="fade-up">

            <div className="row gy-4 align-items-center">

              <div className="col-lg-6">
                <img src={stats} alt="" className="img-fluid" />
              </div>

              <div className="col-lg-6">

                <div className="stats-item d-flex align-items-center">
                  <CounterComponent start={0} end={statistics.students} />
                  <p><strong>Happy Customers</strong> taking weekly lessons</p>
                </div>

                <div className="stats-item d-flex align-items-center">
                  <CounterComponent start={0} end={statistics.lessonsTaught} />
                  <p><strong>Lessons Taught</strong> quality lessons taught</p>
                </div>

                <div className="stats-item d-flex align-items-center">
                  <CounterComponent start={0} end={statistics.minutesLearned} />
                  <p><strong>Minutes Of Learning</strong> dedicated to personal growth</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        </>
    );
  };



export default StatsSection;