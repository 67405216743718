import { ChangeEvent, useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { Student } from '../types/Student';
import Loading from './Loading';
import { deleteStudent, fetchDaysOfTheWeek, fetchStudentById, fetchTeachers, fetchTimesOfDay, fetchTimesZones, postStudent } from '../functions/fetchEntities';


function EditStudent() {

const [validated, setValidated] = useState(false);
const [selectedStudent, setSelectedStudent] = useState({} as Student);
const [dow, setDaysOfTheWeek] = useState([] as string[]);
const [tod, setTimesOfDay] = useState([] as string[]);
const [timeZones, setTimesZones] = useState([] as string[]);
const [teachers, setTeachers] = useState([] as string[]);

const navigate = useNavigate();

const { id } = useParams();
const parsedId = id !== undefined ? id : "";

  useEffect(() => {

    if (parsedId !== "new") {
        fetchStudentById(parsedId)
            .then((data) => setSelectedStudent(data));
    }
      
    setDaysOfTheWeek(fetchDaysOfTheWeek())
    setTimesOfDay(fetchTimesOfDay())
    setTimesZones(fetchTimesZones())
    setTeachers(fetchTeachers())
        
    }, [parsedId]);

 
    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        setSelectedStudent({...selectedStudent, name: name});
    }

    const handleNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
      const number = event.target.value;
      setSelectedStudent({...selectedStudent, phoneNumber: number});
    }

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
      const email = event.target.value;
      setSelectedStudent({...selectedStudent, emailAddress: email});
    }

    const handleDOWChange = (event: ChangeEvent<HTMLSelectElement>) => {
      const dow = event.target.value;
      setSelectedStudent({...selectedStudent, dayOfTheWeek: dow});
    }

    const handleTODChange = (event: ChangeEvent<HTMLSelectElement>) => {
      const tod = event.target.value;
      setSelectedStudent({...selectedStudent, timeOfTheDay: tod});
    }

    const handleTimeZoneChange = (event: ChangeEvent<HTMLSelectElement>) => {
      const timeZone = event.target.value;
      setSelectedStudent({...selectedStudent, timeZone: timeZone});
    }

    const handleTeacherChange = (event: ChangeEvent<HTMLSelectElement>) => {
      const teacher = event.target.value;
      setSelectedStudent({...selectedStudent, teacher: teacher});
    }

    const handleFeeChange = (event: ChangeEvent<HTMLInputElement>) => {
      const number = parseInt(event.target.value);
      setSelectedStudent({...selectedStudent, fee: number});
    }

    const handleSubmit = async (event:any) => {
        const form = event.currentTarget;
        event.preventDefault();
    
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{
            await postStudent(selectedStudent);
            navigate('/Students', {replace: true});
        }
        setValidated(true);
      };

      const handleDelete = async (event: any) => {
        event.preventDefault();
    
        await deleteStudent(parsedId);
    
        navigate('/Students', {replace: true});
      }
    

    return (
        <>
             {parsedId === "new" || selectedStudent.id !== undefined ? 
                <div>
                    <h1>Edit Student</h1>
                    <div className='form-background'>
                    <div className='form-container'>
                        <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)}>

                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Student Name</Form.Label>
                            <Form.Control 
                            type="text" 
                            placeholder="Student Name" 
                            onChange={handleNameChange} 
                            value={selectedStudent.name} 
                            required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formNumber">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control 
                            type="text" 
                            placeholder="Phone Number" 
                            onChange={handleNumberChange} 
                            value={selectedStudent.phoneNumber} 
                            required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formEmail">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control 
                            type="text" 
                            placeholder="Email Address" 
                            onChange={handleEmailChange} 
                            value={selectedStudent.emailAddress} 
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formDOW">
                            <Form.Label>Day of the Week</Form.Label>
                            <select className="form-select" aria-label="Class" onChange={handleDOWChange}>
                                {dow.map((d) => (
                                    <option value={d} selected={selectedStudent.dayOfTheWeek === d}>{d}</option>
                                ))}
                            </select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formTOD">
                            <Form.Label>Time Of Day</Form.Label>
                            <select className="form-select" aria-label="Class" onChange={handleTODChange}>
                                {tod.map((d) => (
                                    <option value={d} selected={selectedStudent.timeOfTheDay === d}>{d}</option>
                                ))}
                            </select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formTimeZone">
                            <Form.Label>Time Zone</Form.Label>
                            <select className="form-select" aria-label="Class" required onChange={handleTimeZoneChange}>
                                {timeZones.map((d) => (
                                    <option value={d} selected={selectedStudent.timeZone === d}>{d}</option>
                                ))}
                            </select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formTeacher">
                            <Form.Label>Teacher</Form.Label>
                            <select className="form-select" aria-label="Class" onChange={handleTeacherChange}>
                                {teachers.map((d) => (
                                    <option value={d} selected={selectedStudent.teacher === d}>{d}</option>
                                ))}
                            </select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formFee">
                            <Form.Label>Fee</Form.Label>
                            <Form.Control 
                            type="number" 
                            placeholder="Fee" 
                            onChange={handleFeeChange} 
                            value={selectedStudent.fee} 
                            required
                            />
                        </Form.Group>

                        <Button className='edit-form-submit' variant="primary" type="submit">
                            Save
                        </Button>
                        <Button className='edit-form-submit' variant="primary" type="submit" onClick={event => handleDelete(event)}>
                          Delete
                        </Button>

             
                        </Form>

                    </div>
                    </div>
                </div> : <Loading />
             }
        </>
  )
}

export default EditStudent;