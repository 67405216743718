import React, { useState, useEffect } from 'react';

type CounterComponentProps = {
  start: number;
  end: number;
};

const CounterComponent: React.FC<CounterComponentProps> = ({ start, end }) => {
  const [count, setCount] = useState<number>(start);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => (prevCount < end ? prevCount + 1 : end));
    }, 1); // Adjust time interval as needed

    return () => clearInterval(interval);
  }, [end]);

  return <span className="purecounter">{count}</span>;
};

export default CounterComponent;
